import React from "react"
import { Route } from "react-router-dom"
import AuthGuard from "../guards/authGuards"
import Overview from "../pages/Dashboard/overview"
import About from "../pages/About/About"
import Eibor from "../pages/EIBOR/Eibor"
import Products from "../pages/Product/Products"
import ProfilePage from "../pages/Profile/ProfileView"
import Leads from "../pages/Leads/OverallLeads"
import UserManagement from "../pages/Accounts/UserList"

const AuthRoutes = [
    <Route key="Dashboard" path="/dashboard" element={<AuthGuard name="dashboard" component={<Overview />} />} />,
    <Route key="About" path="/about" element={<AuthGuard name="about" component={<About />} />} />,
    <Route key="Leads" path="/leads" element={<AuthGuard name="leads" component={<Leads />} />} />,
    <Route key="UserManagement" path="/user-management" element={<AuthGuard name="UserManagement" component={<UserManagement />} />} />,

    <Route key="EIBOR" path="/eibor" element={<AuthGuard name="eibor"  component={<Eibor />} />} />,
    <Route key="Products" path="/products" element={<AuthGuard name="products"  component={<Products />} />} />,
    <Route key="Profile" path="/profile" element={<AuthGuard name="profilePage"  component={<ProfilePage />} />} />,
]

export default AuthRoutes