import React, { useState } from 'react'

const PaginationTable = ({ totalItems, itemsPerPage, pageChange }) => {
    const [currentPage, setCurrentPage] = useState(1)

    // Calculate the total number of pages
    const totalPages = Math.ceil(totalItems / itemsPerPage)

    // Calculate the start and end page numbers for the pagination bar
    let startPage, endPage
    if (totalPages <= 5) {
        startPage = 1
        endPage = totalPages
    } else {
        if (currentPage <= 3) {
            startPage = 1
            endPage = 5
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4
            endPage = totalPages
        } else {
            startPage = currentPage - 2
            endPage = currentPage + 2
        }
    }

    // Function to handle page click
    const handlePageClick = (page) => {
        setCurrentPage(page)
        pageChange(page)
    }

    return (
        <div className="row">
            <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="basic-datatable_info" role="status" aria-live="polite">
                    Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} entries
                </div>
            </div>
            <div className="col-sm-12 col-md-7">
                <div className="dataTables_paginate paging_simple_numbers" id="basic-datatable_paginate">
                    <ul className="pagination pagination-rounded justify-content-end m-0">
                        <li className={`paginate_button page-item ${currentPage === 1 ? 'previous disabled' : 'previous'}`}>
                            <div className="page-link toggle" onClick={() => handlePageClick(1)}>
                                <i className="ri-skip-left-line"></i>
                            </div>
                        </li>
                        <li className={`paginate_button page-item ${currentPage === 1 ? 'previous disabled' : 'previous'}`}>
                            <div className="page-link toggle" onClick={() => handlePageClick(currentPage - 1)}>
                                <i className="ri-arrow-left-s-line"></i>
                            </div>
                        </li>
                        {Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage).map((page) => (
                        <li key={page} className={`paginate_button page-item ${page === currentPage ? 'active' : ''}`}>
                            <div className="page-link toggle" onClick={() => handlePageClick(page)}>
                                {page}
                            </div>
                        </li>
                        ))}
                        <li className={`paginate_button page-item ${currentPage === totalPages ? 'next disabled' : 'next'}`}>
                            <div className="page-link toggle" onClick={() => handlePageClick(currentPage + 1)}>
                                <i className="ri-arrow-right-s-line"></i>
                            </div>
                        </li>
                        <li className={`paginate_button page-item ${currentPage === totalPages ? 'next disabled' : 'next'}`}>
                            <div className="page-link toggle" onClick={() => handlePageClick(totalPages)}>
                                <i className="ri-skip-right-line"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PaginationTable