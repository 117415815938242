import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AuthRoutes from './routes/authRoutes'
import UnAuthRoutes from './routes/unAuthRoutes'
import Error from './pages/Errors/error'
import { useAuth } from './context/authContext'
import Login from './pages/Login/login'
import Overview from './pages/Dashboard/overview'
import Layout from './layout/layout'
import { SetAuthorizationHeader, removeAuthorizationHeader } from './config/axiosInterceptor'
import { checkAuth } from './actions/authController'
import RemoveAccount from './pages/RemoveAccount/removeAccount'


const App = () => {
  const { login, logout, isLogin } = useAuth()
  const [loader, setLoader] = useState(false)
  const [isAuth, setIsAuth ] = useState(false)

  const startup = async(dt) => {
    await SetAuthorizationHeader(dt)
    let { role, username, status, eid } = await checkAuth(dt)
    if(status == "success") {
      setIsAuth(true)
      console.log("check this please")
      login(dt, role, username, eid)
    } else {
      await removeAuthorizationHeader()
      await logout()
    }
    setLoader(true)
  }

  useEffect(() => {
    let doc = document.querySelector("html")
    let isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

    if(doc.getAttribute("data-bs-theme") == null) doc.setAttribute("data-bs-theme", isDarkMode ? "dark" : "light")
  })

  useEffect(() => {
    let dt = localStorage.getItem("token")
    if(dt) startup(dt)
    else setLoader(true)
  }, [])

  return (
    <>
      <Router>
        <Routes>
            <>
              {AuthRoutes}
              {UnAuthRoutes}
              <Route key="Error" path="*" element={<Error auth={isAuth} code={404} />}></Route>
              <Route key="Default" path="/" element={
                (!isAuth ? <Login /> : <Layout><Overview /></Layout>)
              }/>
              <Route key="RemoveAccount" path="/removeAccount" element={<RemoveAccount/>} />
            </>
        </Routes>
      </Router>
    </>
  )
}

export default App