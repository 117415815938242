import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const ErrorPage = ({ code, auth }) => {
    const navigate = useNavigate()

    useEffect(() => {
        console.log("check auth", auth)
    }, [auth])

    return (
        <div className="row justify-content-center items" style={{ height: "100vh" }}>
            <div className="col-12">
                <div className="d-flex flex-column h-100" style={{ placeContent: "center" }}>
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="text-center">
                                <h1 className="text-error mb-4">
                                    {code}
                                </h1>
                                <h2 className="text-uppercase text-danger mt-3">
                                    {
                                        code == 401 ? 
                                            "Unauthorized access"
                                        :
                                            "Page not found"
                                    }
                                </h2>
                                <p className="text-muted mt-3">
                                    It's looking like you may have taken a wrong turn. Don't worry... it
                                    happens to the best of us. Here's a
                                    little tip that might help you get back on track.
                                </p>
                                {
                                    !auth ? 
                                        <div className="btn btn-soft-danger mt-3" onClick={() => {navigate("/login")}}>
                                            <i className="ri-home-4-line me-1"></i> 
                                            Back to Login
                                        </div>
                                    :
                                        <div className="btn btn-soft-danger mt-3" onClick={() => {navigate("/dashboard")}}>
                                            <i className="ri-home-4-line me-1"></i> 
                                            Back to Dashboard
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage