import React from "react"
import logoImg from "../assets/images/logoWhite.png"
import logoImgSm from "../assets/images/logoSquareWhite.png"
import { useNavigate } from "react-router-dom"
import { useAuth } from '../context/authContext'
import { useLocation } from "react-router-dom"

const Sidebar = () => {
    const navigate = useNavigate()
    const { logout, role } = useAuth()
    const location = useLocation()

    const getClassName = (path) => {
        return location.pathname === path ? 'side-nav-link toggle active' : 'side-nav-link toggle'
    }

    return (
        <div className="leftside-menu">
            <a href="index.html" className="logo logo-light">
                <span className="logo-lg">
                    <img src={logoImg} alt="logo" />
                </span>
                <span className="logo-sm">
                    <img src={logoImgSm} alt="small logo" />
                </span>
            </a>

            <a href="index.html" className="logo logo-dark">
                <span className="logo-lg">
                    <img src={logoImg} alt="dark logo" />
                </span>
                <span className="logo-sm">
                    <img src={logoImgSm} alt="small logo" />
                </span>
            </a>

            <div className="h-100" id="leftside-menu-container" data-simplebar>

                <ul className="side-nav">
                    <li className="side-nav-title">Main</li>
                    <li className="side-nav-item">
                        <div onClick={() => { navigate("/dashboard") }}
                            className={getClassName('/dashboard')}>
                            <i className="ri-dashboard-3-line"></i>
                            <span> Dashboard </span>
                        </div>
                    </li>
                    <li className="side-nav-item">
                        <div onClick={() => { navigate("/leads") }}
                            className={getClassName('/leads')}>
                            <i className="ri-user-2-line"></i>
                            <span> Leads </span>
                        </div>
                    </li>
                    {
                        role != 3 && 
                            <li className="side-nav-item">
                                <div onClick={() => { navigate("/user-management") }}
                                    className={getClassName('/user-management')}>
                                    <i class="bi bi-person-lines-fill"></i>
                                    <span> User Management </span>
                                </div>
                            </li>
                    }
                    {/* <li className="side-nav-item">
                        <div onClick={() => {navigate("/products")}}
                            className={getClassName('/products')}>
                            <i className="ri-user-3-line"></i>
                            <span> Products </span>
                        </div>
                    </li>
                    <li className="side-nav-item">
                        <div onClick={() => {navigate("/eibor")}}
                            className={getClassName('/eibor')}>
                            <i className="ri-user-4-line"></i>
                            <span> EIBOR </span>
                        </div>
                    </li> */}

                    <li className="side-nav-title">Settings</li>
                    {/* <li className="side-nav-item">
                        <div onClick={() => {navigate('/profile')}}
                            className={getClassName('/profile')}>
                            <i className="ri-profile-fill"></i>
                            <span> Profile </span>
                        </div>
                    </li> */}
                    <li className="side-nav-item">
                        <div onClick={() => { logout(); navigate('/login') }}
                            className='side-nav-link toggle'>
                            <i className="ri-logout-box-line"></i>
                            <span> Logout </span>
                        </div>
                    </li>
                </ul>

                <div className="clearfix"></div>
            </div>
        </div>
    )
}

export default Sidebar