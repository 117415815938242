import React, { useState } from "react"
import { Form, InputGroup } from "react-bootstrap"

const SearchBar = ({ onSearch, searchText, searchType }) => {
    const [searchTerm, setSearchTerm] = useState("")

    const handleChange = (event) => {
        setSearchTerm(event.target.value)
    }

    const handleSearch = async () => {
        onSearch(searchTerm, searchType)
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSearch()
        }
    }

    return (
        <div className="d-flex align-items-center">
            <div className="input-group">
                <span className="input-group-text" id="search-icon">
                    <i className="bi bi-search" onClick={handleSearch} style={{ cursor: 'pointer' }}></i>
                </span>
                <Form.Control
                    type="text"
                    placeholder={searchText}
                    value={searchTerm}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className="form-control"
                />
                <InputGroup.Text
                    onClick={handleSearch}
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        padding: "0 15px",
                    }}
                >
                </InputGroup.Text>
            </div>
        </div>
    )
}

export default SearchBar
