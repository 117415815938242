import axios from "axios"
import { CreateToken } from "../config/CreateToken"

const apiUrl = process.env.REACT_APP_BASE_URL

export const fetchTotalLeads = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchTotalLeads`
        })
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchOverview = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchOverview`
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}


export const deleteAccount = async (data) => {
    try {
        await axios({
            'method': 'get',
            'url': `${apiUrl}/deleteAccount`,
            params: { data }
        })

        return {
            status: "success"
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchLeads = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchLeads`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchLeadsCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchLeadsCount`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchLeadSteps = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchLeadSteps`
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const createLead = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/createLead`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchLeadDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchLeadDetails`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const updateLeadsInfo = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/updateLeadsInfo`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const updateLeadsInfoStatus = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/updateLeadsInfoStatus`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const deleteLead = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/deleteLead`,
            data: { token: CreateToken(data) }
        })

        console.log("Server Response:", response)

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchUsers = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchUsers`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data,
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchUsersCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchUsersCount`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data,
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const createNewUser = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/createNewUser`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchUserDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/fetchUserDetails`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const updateUsersInfo = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/updateUsersInfo`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const deleteUser = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/deleteUser`,
            data: { token: CreateToken(data) }
        })

        console.log("Server Response:", response)

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect details entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const clientFetchSupportLeads = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/clientFetchSupportLeads`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const clientPasswordChange = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/clientPasswordChange`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status === "401") {
            return {
                status: "failed",
                message: "Authentication failed"
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status",
            message: err.message
        }
    }
}

export const clientFetchFAQ = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/clientFetchFAQ`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const clientFetchSupportDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `${apiUrl}/clientFetchSupportDetails`,
            params: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "There is an error. Please refresh again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const deleteOtpSubmit = async (data) => {
    try {
        await axios({
            'method': 'get',
            'url': `${apiUrl}/deleteOtp`,
            params: { data }
        })

        return {
            status: "success"
        }
    } catch (err) {
        if (err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect OTP entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}