import axios from "axios"

const apiUrl = process.env.REACT_APP_BASE_URL

export const checkLogin = async (dt) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/login`,
            data: { token: dt }
        })

        return {
            status: "success",
            message: response.data.message,
            token: response.data.token,
            role: response.data.role,
            un: response.data.username,
            eid: response.data.eid
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.message
        }
    }
}

export const checkAuth = async (token) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `${apiUrl}/checkAuth`,
            data: { token }
        })

        return {
            status: "success",
            username: response.data.data.username,
            role: response.data.data.role,
            eid: response.data.data.id
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}