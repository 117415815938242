import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Modal, Form, } from "react-bootstrap"
import Select from "react-select"
import uaeData from "../../dataset/locationData.json"
import { ExtractInitials } from "../../config/funcs"
import {
    createLead,
    fetchLeads,
    fetchLeadsCount,
    fetchLeadDetails,
    deleteLead,
    fetchLeadSteps,
    updateLeadsInfo,
    updateLeadsInfoStatus,
} from "../../actions/commonController"
import { useAuth } from "../../context/authContext"
import PaginationTable from "../../components/paginationTable"
import SearchBar from "../../components/searchBar"
import { utcDate, formatDate } from "../../config/utcDate"

const options = [
    { value: 'Pakistani', label: 'Pakistani' },
    { value: 'Indian', label: 'Indian' },
    { value: 'American', label: 'American' }
]

const initialValue = {
    leadId: "",
    agentid: "",
    name: "",
    email: "",
    phone: "",
    nationality: "",
    purchasetype: "",
    residencestatus: "",
    empstatus: "",
    income: "",
    age: "",
    propertyvalue: "",
    loantovalue: "",
    city: "",
    area: "",
    mortgagetype: "",
    loantype: "",
    fixedrate: "",
    mortgageterm: "",
    agentId: "",
    agentnotes: "",
    leadstatusstep: "",
    outstandingloan: "",
    currentlender: "",
    clientmonthobligations: "",
    clientempsector: "",
    applicationtype: "",
    createdAt: "",
    updatedAt: "",
    leadStatus: "",
    leadStatusDesc: "",
    leadReason: ""
}

const Leads = () => {
    const [data, setData] = useState(null)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [leadToDelete, setLeadToDelete] = useState(null)
    const [statusData, setStatusData] = useState({})
    const [selectedLead, setSelectedLead] = useState(null)
    const [leadSteps, setLeadSteps] = useState([])
    const [formValue, setFormValue] = useState(initialValue)
    const [totalItems, setTotalItems] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [searchTerm, setSearchTerm] = useState("")
    const [modalLoader, setModalLoader] = useState(false)
    const [editModalLoader, setEditModalLoader] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [initLoad, setInitLoad] = useState(false)

    const { eid, role, logout } = useAuth()

    const { agentid, name, email, phone, empstatus, income, age, nationality,
        residencestatus, mortgagetype, loantype, fixedrate, mortgageterm,
        purchasetype, propertyvalue, loantovalue, city, area, leadId, leadStatus,
        leadStatusDesc, leadReason } = formValue


    const getBadgeClass = (status) => {
        switch (status) {
            case "In Progress":
                return "bg-primary"
            case "Rejected":
                return "bg-danger"
            case "Freezed":
                return "bg-warning"
            case "Completed":
                return "bg-success"
            default:
                return "bg-secondary"
        }
    };


    const handleChange = (e) => {
        let { name, value } = e.target

        setFormValue({
            ...formValue,
            [name]: value,
        })
    }

    const handleEditChange = (e) => {
        let { name, value } = e.target

        setFormValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }))
    }

    const handleSelectChange = (selectedOption, fieldName) => {
        setFormValue((prevValues) => ({
            ...prevValues,
            [fieldName]: selectedOption ? selectedOption.value : "",
        }))
    }

    const handleSelect = (e) => {
        let { name, value } = e.target

        setFormValue({
            ...formValue,
            [name]: value,
        })
    }

    const openCreateModal = () => {
        setFormValue(initialValue)
        setIsModalOpen(true)
        setShowCreateModal(true)
    }

    const closeCreateModal = () => {
        setIsModalOpen(false)
        setShowCreateModal(false)
    }

    const openDetailsModal = () => setShowDetailsModal(true)
    const closeDetailsModal = () => setShowDetailsModal(false)

    const handleEdit = async (leadId) => {
        setIsModalOpen(true)
        setShowEditModal(true)

        try {
            let APIData = {
                leadid: leadId,
            }

            const { status, data } = await fetchLeadDetails(APIData)
            if (status === "success") {
                const leadData = data[0]

                const mappedData = {
                    leadId: leadId,
                    name: leadData.name,
                    email: leadData.email,
                    phone: leadData.phone,
                    nationality: leadData.nationality,
                    purchasetype: leadData.purchasetype,
                    residencestatus: leadData.residencetstatus,
                    empstatus: leadData.clientempstatus,
                    income: leadData.clientsalary,
                    age: leadData.clientage,
                    propertyvalue: leadData.propertyvalue,
                    loantovalue: leadData.loantovalue,
                    city: leadData.propertyemirate,
                    area: leadData.propertyarea,
                    mortgagetype: leadData.mortgagetype,
                    loantype: leadData.loantype,
                    fixedrate: leadData.fixedrateterm,
                    mortgageterm: leadData.mortgageterm,
                    agentId: leadData.agentid,
                    leadstatus: leadData.leadstatus,
                }

                setFormValue(mappedData)
            } else {
                toast.error("Failed to fetch lead details")
            }
        } catch (error) {
            toast.error("An error occurred while fetching lead details")
        }
    }

    const closeEditModal = () => {
        setIsModalOpen(false)
        setShowEditModal(false)
        setFormValue(initialValue)
    }

    const handleCreateLead = async () => {
        try {
            const APIData = {
                name: name,
                email: email,
                phone: phone,
                nationality: nationality.value,
                purchaseType: purchasetype,
                residenceStatus: residencestatus,
                employmentStatus: empstatus,
                monthlyIncome: income,
                age: age,
                propertyValue: propertyvalue,
                loanToValue: loantovalue,
                emirate: city,
                area: area,
                mortgageType: mortgagetype,
                loanType: loantype,
                fixedRateTerm: fixedrate,
                mortgageTerm: mortgageterm,
                agentId: eid,
            }

            setModalLoader(true)

            const { status, error } = await createLead(APIData)
            if (status === "success") {
                toast.success("Lead created successfully!")
                closeCreateModal()
                loadData()
            } else {
                toast.error(error)
            }
        } catch (err) {
            toast.error("An error occurred while creating the lead")
        } finally {
            setModalLoader(false)
        }
    }

    const handleViewDetails = async (leadId) => {
        setModalLoader(true)
        setShowDetailsModal(true)

        try {

            let APIData = {
                leadid: leadId,
            }

            const { status, data } = await fetchLeadDetails(APIData)
            if (status === "success") {
                setSelectedLead(data[0])
                console.log("data", data)
            } else {
                toast.error("Failed to fetch lead details")
            }
        } catch (error) {
            toast.error("An error occurred while fetching lead details")
        } finally {
            setModalLoader(false)
        }
    }

    const handleUpdateLead = async () => {

        try {
            const updatedAPIData = {
                leadId: formValue.leadId,
                name: formValue.name,
                email: formValue.email,
                phone: formValue.phone,
                nationality: formValue.nationality,
                purchasetype: formValue.purchasetype,
                residencetstatus: formValue.residencestatus,
                clientempstatus: formValue.empstatus,
                clientsalary: formValue.income,
                clientage: formValue.age,
                propertyvalue: formValue.propertyvalue,
                loantovalue: formValue.loantovalue,
                propertyemirate: formValue.city,
                propertyarea: formValue.area,
                mortgagetype: formValue.mortgagetype,
                loantype: formValue.loantype,
                fixedrateterm: formValue.fixedrate,
                mortgageterm: formValue.mortgageterm,
                agentid: formValue.agentId,
                leadstatus: formValue.leadstatus,
            }

            setEditModalLoader(true)

            console.log("Sending updated lead data to API:", updatedAPIData)

            const { status, error } = await updateLeadsInfo(updatedAPIData)
            if (status === "success") {
                toast.success("Lead updated successfully")
                closeEditModal()
            } else {
                toast.error(error)
            }
        } catch (error) {
            toast.error("An error occurred while updating the lead")
        } finally {
            setEditModalLoader(false)
        }
    }

    const handleChangeStatus = async (aid, ln, leadId, ls, lsd) => {
        setSelectedLead(leadId)
        setShowStatusModal(true)

        setFormValue((prevValues) => ({
            ...prevValues,
            'leadStatus': ls,
            'leadStatusDesc': lsd,
            'name': ln,
            'agentid': aid
        }))

        let { data, status } = await fetchLeadSteps()
        if (status == "success") setLeadSteps(data)
    }

    const handleSearch = async (searchData, type) => {
        setSearchTerm(searchData)
    }

    const closeStatusModal = () => {
        setShowStatusModal(false)
    }

    const handleSubmitStatusChange = async () => {
        try {
            setModalLoader(true)
            let APIData = {
                agentid: agentid,
                leadname: name,
                leadid: selectedLead,
                leadstatus: leadStatus,
                leadstatusstep: leadStatusDesc,
                reason: leadReason
            }

            console.log(APIData)

            let { status, error } = await updateLeadsInfoStatus(APIData)
            if (status === "success") {
                toast.success("Lead status updated successfully")
                closeStatusModal()
                loadData()
            } else {
                toast.error(error)
            }
        } catch (error) {
            toast.error("An error occurred while updating the status")
        } finally {
            setModalLoader(false)
        }
    }

    const handleDeleteClick = (leadId) => {
        setLeadToDelete(leadId)
        setShowDeleteModal(true)
    }

    const handleConfirmDelete = async () => {
        if (leadToDelete) {
            setDeleteLoader(true)
            try {
                const deleteData = {
                    leadId: leadToDelete

                }
                const { status, error } = await deleteLead(deleteData)

                if (status === "success") {
                    toast.success("Lead deleted successfully")
                    setShowDeleteModal(false)

                    loadData()
                } else {
                    toast.error(error || "Failed to delete lead")
                }
            } catch (err) {
                toast.error("An error occurred while deleting the lead")
            } finally {
                setDeleteLoader(false)
            }
        }
    }

    const handleCancelDelete = () => {
        setLeadToDelete(null)
        setShowDeleteModal(false)
    }

    const initialization = async () => {
        try {
            let APIData = {
                agentId: eid,
                search: searchTerm,
                status: empstatus,
            }

            setLoader(true)
            let { status, data } = await fetchLeadsCount(APIData)

            if (status === "success") {
                setTotalItems(data)
                await loadData()
                setInitLoad(true)
            } else {
                console.error("Error fetching leads:", status)
            }
        } catch (err) {
            console.error("Error in loadData function", err)
        } finally {
            setLoader(false)
        }
    }

    const loadData = async () => {
        try {
            let APIData = {
                agentId: eid,
                role: role,
                search: searchTerm,
                page: page,
                quantity: quantity,
                status: empstatus,
            }

            setLoader(true)
            let { status, data } = await fetchLeads(APIData)
            if (status === "success") {
                setData(data)
                setPage(page)
            } else {
                console.error("Error fetching leads:", status)
                setLoader(false)
            }
        } catch (error) {
            console.error("Error in loadData function:", error)
        }
        setLoader(false)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    const handleItemsPerPageChange = (newItemsPerPage) => {
        setQuantity(newItemsPerPage)
        setPage(1)
    }

    useEffect(() => {
        if (initLoad) loadData()
    }, [searchTerm, page, quantity])

    useEffect(() => {
        if (eid) initialization()
    }, [eid])

    return (
        <div className="row">
            <div className={`col-12 pt-`}>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h4 className="header-title m-0">All Leads</h4>
                            <Button style={{ width: "auto", marginLeft: "20px" }} onClick={openCreateModal}>
                                + Create Lead
                            </Button>
                        </div>
                        <div className="d-flex align-items-center">
                            <SearchBar
                                onSearch={handleSearch}
                                searchText="Search by name..."
                                searchType="lead"
                            />
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <table className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Agent Name</th>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th>Details</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loader ? (
                                            <tr>
                                                <td colSpan={10} className="text-center" style={{ paddingTop: "50px" }}>
                                                    <h4>
                                                        <span className="spinner-border" role="status" aria-hidden="true" style={{ width: "20px", height: "20px" }}></span>
                                                        <span> Loading data...</span>
                                                    </h4>
                                                </td>
                                            </tr>
                                        ) : data === null ? null : data.length > 0 ? (
                                            data.map((item, index) => (
                                                <tr className="selectableTable" key={index}>
                                                    <td className="align-content-center">
                                                        {(page - 1) * quantity + index + 1}
                                                    </td>
                                                    <td className="align-content-center">{item.agentName}</td>
                                                    <td className="align-content-center">{item.name}</td>
                                                    <td className="align-content-center">{item.phone}</td>
                                                    <td className="align-content-center">{item.email}</td>
                                                    {/* <td className="align-content-center">{item.leadstatus}</td> */}
                                                    <td style={{ alignContent: "center" }}>
                                                        {/* <span className="badge bg-primary p-1 px-2">{item.leadstatus}</span>
                                                         */}
                                                        <span className={`badge ${getBadgeClass(item.leadstatus)} custom-badge`}>
                                                            {item.leadstatus}
                                                        </span>
                                                    </td>
                                                    <td className="align-content-center">{formatDate(utcDate(item.createdAt))}</td>
                                                    <td className="align-content-center">{formatDate(utcDate(item.updatedAt))}</td>
                                                    <td className="align-content-center">
                                                        <button type="button" className="btn btn-primary" sx={{ height: "15px", width: "15px" }} onClick={() => handleViewDetails(item.id)}>
                                                            View
                                                        </button>
                                                    </td>
                                                    <td className="align-content-center">
                                                        <Button variant="secondary" className="mx-2 me-2 mb-2" onClick={() => handleEdit(item.id)}>
                                                            <i className="ri-edit-2-fill me-1"></i>
                                                            <span>Edit</span>
                                                        </Button>
                                                        <Button variant="success" className="mx-2 mb-2" onClick={() => handleChangeStatus(item.agentid, item.name, item.id, item.leadstatus, item.leadstatusstep)}>
                                                            <i class="bi bi-arrow-repeat me-1"></i>
                                                            <span>Change Status</span>
                                                        </Button>
                                                        <Button variant="danger" className="mx-2" onClick={() => handleDeleteClick(item.id)}>
                                                            <i className="bi bi-trash-fill me-1"></i>
                                                            <span>Inactive</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={10} className="text-center" style={{ paddingTop: "50px" }}>
                                                    <h4>No Data</h4>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination Component */}
                        <PaginationTable
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            pageChange={handlePageChange}
                            onItemsPerPageChange={handleItemsPerPageChange}
                        />

                    </div>
                </div>
            </div>

            {/* Create Leads Modal */}
            <Modal size="xl" show={showCreateModal} onHide={closeCreateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">
                        <h4 className="pb-2 w-100 text-center">Personal Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" name="name" value={name} onChange={handleChange} placeholder="Enter lead name here" />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Email Address</label>
                                    <input type="text" className="form-control" name="email" value={email} onChange={handleChange} placeholder="Enter email address here" />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Phone Number</label>
                                    <input type="text" className="form-control" name="phone" value={phone} onChange={handleChange} placeholder="Enter phone number here" />
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Employment Status</label>
                                    <select className="form-select" name="empstatus" value={empstatus} onChange={handleSelect}>
                                        <option value={""}>Choose an option</option>
                                        <option value={"employment"}>Employment</option>
                                        <option value={"self-employed"}>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Monthly Income</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="income" value={income} onChange={handleChange} placeholder="Enter income here" />
                                        <div className="input-group-text" data-password="false">
                                            AED
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 mb-3">
                                    <label className="form-label">Age</label>
                                    <input type="text" className="form-control" name="age" value={age} onChange={handleChange} placeholder="Enter age here" />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Nationality</label>
                                    <Select className="customSelect" options={options} value={nationality} name="nationality" onChange={(e) => { setFormValue({ ...formValue, "nationality": e }) }} />
                                </div>
                            </div>
                        </div>

                        <h4 className="pb-2 w-100 text-center">Lead Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Purchase Type</label>
                                    <select className="form-select" name="purchasetype" value={purchasetype} onChange={handleSelect}>
                                        <option>Select purchase type</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Residence Status</label>
                                    <select className="form-select" name="residencestatus" value={residencestatus} onChange={handleSelect}>
                                        <option>Select residence status</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Mortgage Type</label>
                                    <select className="form-select" name="mortgagetype" value={mortgagetype} onChange={handleSelect}>
                                        <option>Select mortgage type</option>
                                        <option>Fixed</option>
                                        <option>Variable</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Loan Type</label>
                                    <select className="form-select" name="loantype" value={loantype} onChange={handleSelect}>
                                        <option>Select loan type</option>
                                        <option>Conventional</option>
                                        <option>Islamic</option>
                                        <option>Top Deal</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Fixed Rate Term</label>
                                    <select className="form-select" name="fixedrate" value={fixedrate} onChange={handleSelect}>
                                        <option>Select fixed rate term</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Mortgage Term</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="mortgageterm" value={mortgageterm} onChange={handleChange} placeholder="Enter years here" />
                                        <div className="input-group-text" data-password="false">
                                            Years
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 className="pb-2 w-100 text-center">Property Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Property Value</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="propertyvalue" value={propertyvalue} onChange={handleChange} placeholder="Property value here" />
                                        <div className="input-group-text" data-password="false">
                                            AED
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Loan To Value</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="loantovalue" value={loantovalue} onChange={handleChange} placeholder="Loan rate here" />
                                        <div className="input-group-text" data-password="false">
                                            %
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">City</label>
                                    <select className="form-select" name="city" value={city} onChange={handleChange}>
                                        <option value="">Select city</option>
                                        {Object.keys(uaeData).map((city) => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Area</label>
                                    <select className="form-select" name="area" value={area} onChange={handleChange}>
                                        <option value={""}>Select area</option>
                                        {uaeData[city]?.map((ar) => (
                                            <option key={ar} value={ar}>
                                                {ar}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCreateModal}>Close</Button>
                    {
                        modalLoader ?
                            <button className="btn btn-primary" disabled>
                                <span className="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Creating...
                            </button>
                            :
                            <Button variant="primary" onClick={handleCreateLead} disabled={modalLoader}>
                                Create Lead
                            </Button>
                    }
                </Modal.Footer>
            </Modal >

            {/* Modal for Lead Details */}
            <Modal size="xl" show={showDetailsModal} onHide={closeDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Lead Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalLoader ? (
                        <div className="text-center">
                            <span className="spinner-border" role="status" aria-hidden="true" style={{ width: "50px", height: "50px" }}>
                            </span>
                        </div>
                    ) : selectedLead ? (
                        <div className="card-body">
                            <h4 className="pb-2 w-100 text-center">Personal Details</h4>
                            <div className="card customCard p-3">
                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Name</label>
                                        <input type="text" className="form-control" name="name" value={selectedLead.name} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Email Address</label>
                                        <input type="text" className="form-control" name="email" value={selectedLead.email} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Phone Number</label>
                                        <input type="text" className="form-control" name="phone" value={selectedLead.phone} disabled />
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <label className="form-label">Employment Status</label>
                                        <input type="text" className="form-control" name="empstatus" value={selectedLead.clientempstatus} disabled />
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <label className="form-label">Monthly Income</label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" name="income" value={selectedLead.clientsalary} disabled />
                                            <div className="input-group-text" data-password="false">
                                                AED
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-3">
                                        <label className="form-label">Age</label>
                                        <input type="text" className="form-control" name="age" value={selectedLead.clientage} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Nationality</label>
                                        <input type="text" className="form-control" name="nationality" value={selectedLead.nationality} disabled />
                                    </div>
                                </div>
                            </div>

                            <h4 className="pb-2 w-100 text-center">Lead Details</h4>
                            <div className="card customCard p-3">
                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Purchase Type</label>
                                        <input type="text" className="form-control" name="purchasetype" value={selectedLead.purchasetype} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Residence Status</label>
                                        <input type="text" className="form-control" name="residencestatus" value={selectedLead.residencetstatus} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Mortgage Type</label>
                                        <input type="text" className="form-control" name="mortgagetype" value={selectedLead.mortgagetype} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Loan Type</label>
                                        <input type="text" className="form-control" name="loantype" value={selectedLead.loantype} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Fixed Rate Term</label>
                                        <input type="text" className="form-control" name="fixedrate" value={selectedLead.fixedrateterm} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label className="form-label">Mortgage Term</label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" name="mortgageterm" value={selectedLead.mortgageterm} disabled />
                                            <div className="input-group-text" data-password="false">
                                                Years
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h4 className="pb-2 w-100 text-center">Property Details</h4>
                            <div className="card customCard p-3">
                                <div className="row">
                                    <div className="col-lg-3 mb-3">
                                        <label className="form-label">Property Value</label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" name="propertyvalue" value={selectedLead.propertyvalue} disabled />
                                            <div className="input-group-text" data-password="false">
                                                AED
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <label className="form-label">Loan To Value</label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" name="loantovalue" value={selectedLead.loantovalue} disabled />
                                            <div className="input-group-text" data-password="false">
                                                %
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <label className="form-label">City</label>
                                        <input type="text" className="form-control" name="city" value={selectedLead.propertyemirate} disabled />
                                    </div>
                                    <div className="col-lg-3 mb-3">
                                        <label className="form-label">Area</label>
                                        <input type="text" className="form-control" name="area" value={selectedLead.propertyarea} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>No details available for this lead.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDetailsModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Edit Lead Info */}
            <Modal size="xl" show={showEditModal} onHide={closeEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Lead Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">
                        <h4 className="pb-2 w-100 text-center">Personal Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={name}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Email Address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        value={email}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="phone"
                                        value={phone}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Employment Status</label>
                                    <select className="form-select" name="empstatus" value={empstatus} onChange={handleEditChange}>
                                        <option value={""}>Choose an option</option>
                                        <option value={"employment"}>Employment</option>
                                        <option value={"self-employed"}>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Monthly Income</label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="income"
                                            value={income}
                                            onChange={handleEditChange}
                                        />
                                        <div className="input-group-text" data-password="false">
                                            AED
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 mb-3">
                                    <label className="form-label">Age</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="age"
                                        value={age}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Nationality</label>
                                    <Select
                                        className="customSelect"
                                        name="nationality"
                                        value={nationality ? { value: nationality, label: nationality } : null}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "nationality")}
                                        options={options}
                                        placeholder="Select nationality"
                                    />
                                </div>
                            </div>
                        </div>

                        <h4 className="pb-2 w-100 text-center">Lead Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Purchase Type</label>
                                    <select className="form-select" name="purchasetype" value={purchasetype} onChange={handleEditChange}>
                                        <option>Choose an option</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Residence Status</label>
                                    <select className="form-select" name="residencestatus" value={residencestatus} onChange={handleEditChange}>
                                        <option>Select residence status</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Mortgage Type</label>
                                    <select className="form-select" name="mortgagetype" value={mortgagetype} onChange={handleEditChange}>
                                        <option>Choose an option</option>
                                        <option>Fixed</option>
                                        <option>Variable</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Loan Type</label>
                                    <select className="form-select" name="loantype" value={loantype} onChange={handleEditChange}>
                                        <option>Choose an option</option>
                                        <option>Conventional</option>
                                        <option>Islamic</option>
                                        <option>Top Deal</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Fixed Rate Term</label>
                                    <select className="form-select" name="fixedrate" value={fixedrate} onChange={handleEditChange}>
                                        <option>Select fixed rate term</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Mortgage Term</label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="mortgageterm"
                                            value={mortgageterm}
                                            onChange={handleEditChange}
                                        />
                                        <div className="input-group-text" data-password="false">
                                            Years
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <h4 className="pb-2 w-100 text-center">Property Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">

                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Property Value</label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="propertyvalue"
                                            value={propertyvalue}
                                            onChange={handleEditChange}
                                        />
                                        <div className="input-group-text" data-password="false">
                                            AED
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Loan To Value</label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="loantovalue"
                                            value={loantovalue}
                                            onChange={handleEditChange}
                                        />
                                        <div className="input-group-text" data-password="false">
                                            %
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">City</label>
                                    <Select
                                        className="customSelect"
                                        name="city"
                                        value={city ? { value: city, label: city } : null}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "city")}
                                        options={Object.keys(uaeData).map((city) => ({
                                            value: city,
                                            label: city,
                                        }))}
                                        placeholder="Select city"
                                    />
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Area</label>
                                    <select className="form-select" name="area" value={area} onChange={handleEditChange}>
                                        <option value={""}>Select area</option>
                                        {uaeData[city]?.map((ar) => (
                                            <option key={ar} value={ar}>
                                                {ar}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeEditModal}>Close</Button>
                    {
                        editModalLoader ?
                            <button className="btn btn-primary" disabled>
                                <span className="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Saving...
                            </button>
                            :
                            <Button variant="primary" onClick={handleUpdateLead} disabled={editModalLoader}>
                                Save changes
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Change Lead Status */}
            <Modal size="sx" show={showStatusModal} onHide={closeStatusModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Lead Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">
                        <Form>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <select className="form-select" name="leadStatus" value={leadStatus} onChange={handleSelect}>
                                    <option value={"In Progress"}>In Progress</option>
                                    <option value={"Rejected"}>Rejected</option>
                                    <option value={"Freezed"}>Freezed</option>
                                    <option value={"Completed"}>Completed</option>
                                </select>
                                {leadStatus === 'In Progress' && (
                                    <Form.Group className="mt-3">
                                        <Form.Label>Lead Status Step</Form.Label>
                                        <select className="form-select" name="leadStatusDesc" value={leadStatusDesc} onChange={handleSelect}>
                                            {
                                                leadSteps && leadSteps.count != 0 && leadSteps.map((item) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Form.Group>
                                )}
                                {(leadStatus === 'Rejected' || leadStatus === 'Freezed') && (
                                    <Form.Group className="mt-3">
                                        <Form.Label>Reason</Form.Label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="leadReason"
                                            value={leadReason}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeStatusModal}>Close</Button>
                    {
                        modalLoader ? (
                            <button className="btn btn-primary" disabled>
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                Saving...
                            </button>
                        ) : (
                            <Button variant="primary" onClick={handleSubmitStatusChange} disabled={modalLoader}>
                                Save changes
                            </Button>
                        )
                    }
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this lead?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelDelete}>
                        No
                    </Button>
                    {
                        deleteLoader ?
                            <button className="btn btn-danger" disabled>
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Deleting...
                            </button>
                            :
                            <Button variant="danger" onClick={handleConfirmDelete} disabled={deleteLoader}>
                                Yes, delete
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Leads