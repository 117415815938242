export const utcDate = (data) => {
    let time = new Date(data)
    return new Date(time.getTime() + time.getTimezoneOffset() * 60000)
}

export const formatDate = (data) => {
    let year = data.getFullYear().toString().padStart(4, '0')
    let month = (data.getMonth() + 1).toString().padStart(2, '0')
    let day = data.getDate().toString().padStart(2, '0')
    let hours = data.getHours().toString().padStart(2, '0')
    let minutes = data.getMinutes().toString().padStart(2, '0')
    let seconds = data.getSeconds().toString().padStart(2, '0')

    return `${year}-${month}-${day} | ${hours}:${minutes}:${seconds}`
}