import React, { useEffect, useState } from 'react'
import Layout from '../layout/layout'
import { useAuth } from '../context/authContext'
import Error from '../pages/Errors/error'

const AuthGuard = ({ name, component }) => {
  const { isLogin, role } = useAuth()
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  useEffect(() => {
    if(isLogin != null && role != null) {
      if(isLogin) {
        if(role == 3 && name == "UserManagement") return setIsAuthenticated(false)
        return setIsAuthenticated(true)
      }
    }
  }, [isLogin, role])

  return (
    isAuthenticated != null && (
      isAuthenticated ? <Layout>{component}</Layout> : <Error code={401} auth={false} />
    )
  )
}

export default AuthGuard