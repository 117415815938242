import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Modal, Form } from "react-bootstrap"
import Select from "react-select"
// import { 
//     // deleteUser 
// } from "../../actions/userController"
import PaginationTable from "../../components/paginationTable"
import SearchBar from "../../components/searchBar"
import {
    fetchUsers,
    fetchUsersCount,
    createNewUser,
    fetchUserDetails,
    updateUsersInfo,
    deleteUser,
} from "../../actions/commonController"
import { useAuth } from "../../context/authContext"
import { utcDate, formatDate } from "../../config/utcDate"

const rolesOptions = [
    { value: '1', label: 'Admin' },
    { value: '2', label: 'Manager' },
    { value: '3', label: 'User' }
]

const initialValue = {
    id: "",
    username: "",
    role: "",
    loginAt: "",
    createdAt: ""
}

const UserList = () => {
    const [data, setData] = useState(null)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [userToDelete, setUserToDelete] = useState(null)
    const [formValue, setFormValue] = useState(initialValue)
    const [totalItems, setTotalItems] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [searchTerm, setSearchTerm] = useState("")
    const [modalLoader, setModalLoader] = useState(false)
    const [editModalLoader, setEditModalLoader] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [initLoad, setInitLoad] = useState(false);
    const { eid } = useAuth
    const {
        id,
        username,
        role,
        loginAt,
        createdAt
    } = formValue

    const handleChange = (e) => {
        let { name, value } = e.target
        setFormValue({
            ...formValue,
            [name]: value,
        })
    }

    const handleRoleChange = (selectedOption) => {
        setFormValue({
            ...formValue,
            role: selectedOption.value
        })
    }

    const openCreateModal = () => {
        setFormValue(initialValue)
        setShowCreateModal(true)
    }

    const closeCreateModal = () => {
        setFormValue(initialValue)
        setShowCreateModal(false)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    const handleItemsPerPageChange = (newItemsPerPage) => {
        setQuantity(newItemsPerPage)
        setPage(1)
    }

    const handleEdit = async (userId) => {
        setFormValue(initialValue)
        setShowEditModal(true)
        try {
            let APIData = {
                userid: userId
            }
            const { data } = await fetchUserDetails(APIData)
            if (data) {
                const userData = data[0]
                setFormValue({
                    userId: userId,
                    username: userData.username,
                    role: userData.role
                })
            } else {
                toast.error("Failed to fetch user details")
            }
        } catch (error) {
            toast.error("An error occurred while fetching user details")
        }
    }

    const closeEditModal = () => setShowEditModal(false)

    const handleCreateUser = async () => {
        try {
            const APIData = {
                username: username,
                role: role,
            }

            console.log("APIData", APIData)
            setModalLoader(true)

            const { status, error } = await createNewUser(APIData)
            if (status === "success") {
                toast.success("User created successfully!")
                closeCreateModal()
                loadData()
            } else {
                toast.error(error)
            }
        } catch (err) {
            toast.error("An error occurred while creating the user")
        } finally {
            setModalLoader(false)
        }
    }

    const handleUpdateUser = async () => {
        try {
            const updatedAPIData = {
                userId: formValue.userId,
                username: username,
                role: role
            }

            setEditModalLoader(true)

            const { status, error } = await updateUsersInfo(updatedAPIData)
            if (status === "success") {
                toast.success("User updated successfully")
                closeEditModal()
                loadData()
            } else {
                toast.error(error)
            }
        } catch (error) {
            toast.error("An error occurred while updating the user")
        } finally {
            setEditModalLoader(false)
        }
    }

    const handleDeleteClick = (userId) => {
        setUserToDelete(userId)
        setShowDeleteModal(true)
    }

    const handleConfirmDelete = async () => {
        if (userToDelete) {
            setDeleteLoader(true)
            try {
                const deleteData = {
                    userId: userToDelete
                }
                const { status, error } = await deleteUser(deleteData)
                if (status === "success") {
                    toast.success("User deleted successfully")
                    setShowDeleteModal(false)
                    loadData()
                } else {
                    toast.error(error || "Failed to delete user")
                }
            } catch (err) {
                toast.error("An error occurred while deleting the user")
            } finally {
                setDeleteLoader(false)
            }
        }
    }

    const initialization = async () => {
        try {
            let APIData = {
                search: searchTerm,
                userStatus: role,
            }

            setLoader(true)
            let { status, data } = await fetchUsersCount(APIData)

            if (status === "success") {
                setTotalItems(data)
                await loadData()
                setInitLoad(true)
            }
        } catch (err) {
            console.error("Error in loadData function", err)
        }
    }

    const loadData = async () => {
        try {
            let APIData = {
                search: searchTerm,
                page: page,
                quantity: quantity,
                userStatus: role,
            }

            setLoader(true)
            const { data } = await fetchUsers(APIData)
            setData(data)
            setPage(page)
        } catch (error) {
            console.error("Error fetching users:", error)
        } setLoader(false)
    }

    useEffect(() => {
        if(initLoad) loadData()
    }, [page, quantity])

    useEffect(() => {
        initialization()
    }, [])

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h4 className="header-title m-0">All Users</h4>
                            <Button style={{ marginLeft: "20px" }} onClick={openCreateModal}>
                                + Create User
                            </Button>
                        </div>
                        <div className="d-flex align-items-center">
                            <SearchBar onSearch={setSearchTerm} searchText="Search by name..." />
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <table className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Username</th>
                                        <th>Role</th>
                                        <th>Login At</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loader ? (
                                            <tr>
                                                <td colSpan={8} className="text-center" style={{ paddingTop: "50px" }}>
                                                    <h4>
                                                        <span className="spinner-border" role="status" aria-hidden="true" style={{ width: "20px", height: "20px" }}></span>
                                                        <span> Loading data...</span>
                                                    </h4>
                                                </td>
                                            </tr>
                                        ) : data === null ? null : data.length > 0 ? (
                                            data.map((item, index) => (
                                                <tr className="selectableTable" key={index}>
                                                    <td className="align-content-center">
                                                        {(page - 1) * quantity + index + 1}
                                                    </td>
                                                    <td className="align-content-center">{item.username}</td>
                                                    <td className="align-content-center">{rolesOptions.find((roleOption) => roleOption.value === String(item.role))?.label || "Unknown"}</td>
                                                    <td className="align-content-center">{formatDate(utcDate(item.loginAt))}</td>
                                                    <td className="align-content-center">{formatDate(utcDate(item.createdAt))}</td>
                                                    <td>
                                                        <Button variant="secondary" className="mx-2 me-2" onClick={() => handleEdit(item.id)}>
                                                            <i className="ri-edit-2-fill me-1"></i>
                                                            <span>Edit</span>
                                                        </Button>
                                                        <Button variant="danger" className="mx-2" onClick={() => handleDeleteClick(item.id)}>
                                                            <i className="bi bi-trash-fill me-1"></i>
                                                            <span>Inactive</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center" style={{ paddingTop: "50px" }}>
                                                    <h4>No Data</h4>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination Component */}
                        <PaginationTable
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            pageChange={handlePageChange}
                            onItemsPerPageChange={handleItemsPerPageChange}
                        />
                    </div>
                </div>
            </div>

            {/* Create User Modal */}
            <Modal size="sx" show={showCreateModal} onHide={closeCreateModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" name="username" value={username} onChange={handleChange} placeholder="Enter username" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="form-label">Role</Form.Label>
                            <Select name="role" options={rolesOptions} value={rolesOptions.find(r => r.value === role)} onChange={handleRoleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCreateModal}>Close</Button>
                    {
                        modalLoader ?
                            <button className="btn btn-primary" disabled>
                                <span className="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Creating...
                            </button>
                            :
                            <Button variant="primary" onClick={handleCreateUser} disabled={modalLoader}>
                                Create User
                            </Button>
                    }
                    {/* <Button variant="primary" onClick={handleCreateUser} disabled={modalLoader}>
                        {modalLoader ? "Creating..." : "Create User"}
                    </Button> */}
                </Modal.Footer>
            </Modal>

            {/* Edit User Modal */}
            <Modal size="sx" show={showEditModal} onHide={closeEditModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" name="username" value={formValue.username} onChange={handleChange} placeholder="Enter user name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Role</Form.Label>
                            <Select name="role" options={rolesOptions} value={rolesOptions.find(r => r.value === String(formValue.role))} onChange={handleRoleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeEditModal}>Close</Button>
                    {
                        editModalLoader ?
                            <button className="btn btn-primary" disabled>
                                <span className="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Saving...
                            </button>
                            :
                            <Button variant="primary" onClick={handleUpdateUser} disabled={editModalLoader}>
                                Save changes
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this user?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>No</Button>
                    {/* <Button variant="danger" onClick={handleConfirmDelete}>Yes, Delete</Button> */}
                    {
                        deleteLoader ?
                            <button className="btn btn-danger" disabled>
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Deleting...
                            </button>
                            :
                            <Button variant="danger" onClick={handleConfirmDelete} disabled={deleteLoader}>
                                Yes, delete
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserList
